import React from "react"
import {Link} from 'gatsby'
import { GithubOutlined, TwitterOutlined } from '@ant-design/icons'
import styled from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const StyledLink = styled.a`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`

class IndexPage extends React.Component {
  render() {
    const siteTitle = ""

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <img style={{ margin: 0 }} src="./LeaCodesLogo.svg" alt="LeaCodes avatar" />
        <h1>
          Hi friend{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>This site is currently under construction.</p>
        <p>In the meantime...</p>
        <StyledLink href="https://github.com/Lean3Viljoen94" target="blank" style={{ color: "black",  textDecoration: "none" }}>
          <GithubOutlined style={{ margin: 20, fontSize: 50 }} src="./github.png" alt="Github logo"/>
        </StyledLink>
        <StyledLink href="https://twitter.com/LeaNieuwoudt" target="blank" style={{ color: "black" }}>
          <TwitterOutlined style={{ margin: 20, fontSize: 50  }} src="./twitter.png" alt="Twitter logo"/>
        </StyledLink>
        {/* <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link> */}
      </Layout>
    )
  }
}

export default IndexPage
